<template>
  <div class="allincome">
     <div class="header flex">
      <div class="dateName">统计年份</div>
      <van-dropdown-menu active-color="#3F7C53">
        <van-dropdown-item v-model="year" :options="option1" />
      </van-dropdown-menu>
    </div>

    <div class="content">
      <van-pull-refresh v-model="isLoading"
                    @refresh="onRefresh">
        <van-list v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad">
          <div class="content-item flex" v-for="(item, index) in incomeList" :key="index">
            <div>
              <div class="income">{{item.dateMonth.substring(5,7)}}月收入
                <span :class="{
                          'income_one':item.status == '1',
                          'income_two': item.status == '1' && item.payStatus == '1',
                          'income_three':item.status == '0'}">{{item.status =='1' ? (item.payStatus == '1' ? '已发薪' : '已确认') : '未确认'}}</span>
              </div>
              <div class="num">{{item.monthServiceFee}}</div>
            </div>
            <div class="see-btn" @click="seeClick(item.id)">{{item.status != '0' ? "去查看" : '去确认'}}</div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { getIncomeList } from '@/utils/api'
import common from '@/utils/common'
export default {
  data() {
    return{
       //year: (new Date().getFullYear()).toString(),
      year: (new Date(new Date().setFullYear(new Date().getFullYear())).getFullYear()).toString(),
      option1: [{ text: '2021年', value: '2021' }, { text: '2022年', value: '2022' }, { text: '2023年', value: '2023' }, { text: '2024年', value: '2024' }],
      incomeList:[],
      finished: false,
      isLoading: false,
      loading: false,
      pageIndex: 1,
      pageSize: 10,
    }
  },
   watch:{
    year(val){
      this.togetIncomeList(val)
      this.year = val
    },
  },
  methods:{
    uploadInvoice(id){

    },
    seeClick(id) {
      this.$router.push({name: 'latestincome', query:{id: id}})
    },
    togetIncomeList(year) {
      getIncomeList({
        uniqueNo: this.$route.query.jumpFrom !=undefined ? this.$route.query.uniqueNo : common.getItem('uniqueNo'),
        year,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }).then(res=>{
        this.loading = false;
        if (res.code == 200) {
          if (res.data && res.data.data && res.data.businessCode == 200) {
            this.finished = !res.data.data.hasNextPage;
            console.log(res);
            if (this.pageIndex == 1) {
              this.incomeList = res.data.data.list;
            } else {
              this.incomeList = this.incomeList.concat(res.data.data.list);
            }
            console.log( this.incomeList);
            if (!this.finished) {
              this.pageIndex++;
            }

          }
        }
      })
    },
    onRefresh() {
      this.isLoading = false
      this.siteList = []
      this.pageIndex = 1
      this.togetIncomeList(this.year)
    },
    onLoad () {
      this.togetIncomeList(this.year)
    },
  }
}
</script>

<style lang="less" scoped>
.allincome{
  .header{
    width: 100%;
    background: #fff;
    .dateName{
      width: 2.5rem;
      flex: 0 0 2.5rem;
      text-align: center;
      line-height: .96rem;
      font-size: 0.3rem;
      color: #1E1E1E;
    }
  }
  .content{
    .content-item{
      margin: 0.32rem 0.3rem;
      padding: 0.22rem 0.4rem 0.24rem;
      box-shadow: 0px 6px 26px 0px rgba(0,0,0,0.08);
      border-radius: 16px;
      justify-content: space-between;
      align-items: center;
    }
    .see-btn{
      width:1.2rem;
      height: 0.5rem;
      text-align: center;
      line-height: 0.5rem;
      background: rgba(63, 124, 83, 0.1);
      border-radius: 25px;
      background: rgba(63, 124, 83, 0.1);
      color: #3F7C53;
      font-size: 0.24rem;
    }
    .income{
      color: #999999;
      font-size: 0.3rem;
      span{
        width: 0.82rem;
        height: 0.32rem;
        border-radius: 8px;
        font-size: 0.2rem;
        padding: 0.01rem 0.08rem;
      }
      .income_one{
        color: #3F7C53;
        border: 1px solid rgba(63, 124, 83, 0.5);
      }
      .income_two{
        color: #4A90E2;
        border: 1px solid rgba(74, 144, 226, 0.5);
      }
      .income_three{
        color: #F5A623;
        border: 1px solid rgba(245, 166, 35, 0.5);
      }
    }
    .num{
      color: #333333;
      font-size: 0.36rem;
    }
  }
}
</style>

<style lang="less">
.allincome{
  .van-dropdown-menu{
    flex: 1;
    .van-dropdown-menu__bar{
      box-shadow: none;
    }
  }
}
</style>